import { Button, ButtonVariant } from "@components/new-ui/button";
import { cn } from "@lib/utils";
import { UseFormRegisterReturn } from "react-hook-form";
import { forwardRef, ReactNode, Ref, useId, useMemo, useRef } from "react";
type Option = Readonly<{
  label: ReactNode;
  value: string;
}>;
type OptionProps = {
  value: string;
  register: UseFormRegisterReturn;
  children: ReactNode;
};
const Option = forwardRef(({
  value,
  register,
  children
}: OptionProps, ref: Ref<HTMLLabelElement>) => {
  const {
    name
  } = register;
  const id = useId();
  const labelId = useMemo(() => encodeURIComponent(`${name}-${id}`), [name, id]);
  return <label htmlFor={labelId} ref={ref} className="w-full">
        <input id={labelId} type="radio" value={value} className="peer" {...register} hidden />

        {children}
      </label>;
});
Option.displayName = "ButtonSwitch-Item";
export type ButtonWrapperProps<O extends Option = Option> = {
  children: ReactNode;
  option: O;
};
type Props<F extends string, O extends Option> = {
  options: readonly O[];
  register: UseFormRegisterReturn<F>;
  selected: string;
  variant?: readonly [enabled?: ButtonVariant, disabled?: ButtonVariant];
  buttonWrapper?: (props: ButtonWrapperProps<O>) => ReactNode;
};
const defaultVariant = ["ghost-blue", "none"] as const;
const defaultButtonWrapper = ({
  children
}: ButtonWrapperProps<Option>) => children;
const ButtonSwitch = <F extends string, O extends Option>({
  selected,
  options,
  register,
  variant = defaultVariant,
  buttonWrapper: ButtonWrapper = defaultButtonWrapper
}: Props<F, O>) => {
  const itemRef = useRef<HTMLLabelElement[]>([]);
  const [enabled = defaultVariant[0], disabled = defaultVariant[1]] = variant;
  return (
    // outer rounding = inner rounding + inner padding
    <fieldset className="inline-flex border border-solid border-[#5E679E]/35 rounded-[9px] p-[3px] bg-[#5E679E]/20" data-sentry-component="ButtonSwitch" data-sentry-source-file="button-switch.tsx">
      {/* inputs */}

      {options.map((option, index) => <Option key={option.value} value={option.value} register={register} ref={el => {
        if (!el) return;
        itemRef.current[index] = el;
      }}>
          <ButtonWrapper option={option}>
            <Button type="button" variant={selected === option.value ? enabled : disabled} disabled={false} className={cn("w-full h-[38px] min-h-[38px] px-[18px] [&_svg]:size-8 pointer-events-auto disabled:pointer-events-auto")} onClick={() => {
            itemRef.current[index].click();
          }}>
              {option.label}
            </Button>
          </ButtonWrapper>
        </Option>)}
    </fieldset>
  );
};
export default ButtonSwitch;