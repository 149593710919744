import { cn } from "@lib/utils";
import { ReactNode } from "react";
type RowProps = {
  children: [gameName: ReactNode, user: ReactNode, betAmount: ReactNode, multiplier: ReactNode, payout: ReactNode];
  className?: string;
  header?: boolean;
};
const Row = ({
  children,
  className,
  header
}: RowProps) => {
  const [gameName, user, betAmount, multiplier, payout] = children;
  return <div data-row={header ? "header" : "body"} className={cn("flex justify-between", "p-4 rounded-md", "[&_*]:font-inherit [&>*]:text-normal", className)} data-sentry-component="Row" data-sentry-source-file="row.tsx">
      <div className="w-1/5 min-w-28 justify-start [&>*]:text-inherit flex items-center leading-normal">
        {gameName}
      </div>

      <div className="w-1/5 justify-center [&>*]:text-inherit hidden items-center @2xl/bets-feed:flex">
        {user}
      </div>

      <div className="w-1/5 justify-center [&>*]:text-inherit hidden items-center @lg/bets-feed:flex">
        {betAmount}
      </div>

      <div className="w-1/5 justify-center [&>*]:text-inherit hidden items-center @sm/bets-feed:flex">
        {multiplier}
      </div>

      <div className={cn("w-1/5 justify-end flex items-center", "[&_*]:text-inherit")}>
        {payout}
      </div>
    </div>;
};
export default Row;