import Arrow from "@assets/icons/general/arrow";
import Hidden from "@assets/icons/general/Hidden";
import ButtonSwitch from "@components/form/button-switch";
import LoadingSpinner from "@components/Loading/LoadingSpinner";
import RollingList from "@components/rolling-list";
import useIsWindowFocused from "@hooks/use-is-window-focused";
import { Dict } from "@hooks/useLanguage";
import { GameHistory, useNewGameHistory } from "@hooks/useSocket";
import useToken from "@hooks/useToken";
import { useUserSession } from "@hooks/useUserSession";
import useWallet from "@hooks/useWallet";
import GamesIcons from "@items/games-icons";
import RewardIcons, { RewardCode } from "@items/RewardIcons";
import ValueDisplay from "@items/ValueDisplay";
import { NODE_API } from "@lib/api/api";
import { useGameHistoryQueue } from "@lib/Queue";
import formatNumberLocale from "@lib/tools/format-number-locale";
import { useLocale } from "@lib/tools/helpers";
import isWin from "@lib/tools/is-win";
import { cn } from "@lib/utils";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import Row from "./row";
const ROOMS = {
  ALL: "ALL",
  USER: "USER"
} as const;
type Room = (typeof ROOMS)[keyof typeof ROOMS];
const bets_url = "v1/game-history";
const fetcher = (opts = {}) => NODE_API.post(bets_url, {
  take: 11,
  ...opts
}).then(res => res.data);
const LocalArrow = ({
  isWin
}: {
  isWin: boolean;
}) => {
  return <div className={cn("border border-solid rounded-[5px] relative w-6 h-6", isWin ? "border-[#ffb300]/50 bg-[#ffb300]/10" : "border-[#5B628C]")} style={{
    ...(isWin && {
      boxShadow: "0px 0px 4px 0px #FFB300 inset, 0px 0px 4px 0px rgba(255, 179, 0, 0.25)"
    })
  }} data-sentry-component="LocalArrow" data-sentry-source-file="index.tsx">
      <Arrow fill={isWin ? "#FFB300" : "#5B628C"} className={cn("w-3", !isWin && "rotate-180", "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2")} data-sentry-element="Arrow" data-sentry-source-file="index.tsx" />
    </div>;
};
const options = [{
  label: "All Bets",
  value: ROOMS.ALL
}, {
  label: "My Bets",
  value: ROOMS.USER
}] as const;
type Fields = {
  "bets-feed-room": Room;
};
const noBets = [];
const BetsFeed = () => {
  const token = useToken();
  const locale = useLocale();
  const isWindowFocused = useIsWindowFocused();
  const router = useRouter();
  const {
    register,
    watch
  } = useForm<Fields>({
    defaultValues: {
      "bets-feed-room": ROOMS.ALL
    }
  });
  const room = watch("bets-feed-room");
  const {
    rawBalance
  } = useWallet();
  const {
    publicId
  } = useUserSession();
  const {
    data: bets,
    mutate
  } = useSWR([bets_url, room], ([, room]) => fetcher(room === ROOMS.USER ? {
    public_id: publicId
  } : undefined), {
    revalidateOnFocus: false
  });
  const {
    queue,
    output,
    id,
    ref
  } = useGameHistoryQueue({
    initial: bets || noBets,
    limit: 10,
    resetsOnInitial: true
  });
  const betHistory = useMemo(() => output || [], [output]);
  const addToQueue = useCallback((item: GameHistory) => {
    if (!isWindowFocused) return;
    if (room !== ROOMS.USER) {
      queue.add(item);
    }
  }, [queue, room, isWindowFocused]);
  useNewGameHistory(addToQueue);
  useEffect(() => {
    if (room !== ROOMS.USER) return;
    mutate();
    // `rawBalance` is intentional here,
    // update "my bets" when wallet changes to keep up with the user's bets
  }, [mutate, room, rawBalance]);
  const prevRoomRef = useRef(room);
  useEffect(() => {
    const timeout = setTimeout(() => {
      prevRoomRef.current = room;
    }, 0);
    return () => clearTimeout(timeout);
  }, [room]);
  return <div className="max-w-[1200px] mx-auto @container/bets-feed w-full px-4" data-sentry-component="BetsFeed" data-sentry-source-file="index.tsx">
      {/* buttons */}
      <ButtonSwitch options={options} register={register("bets-feed-room")} selected={room || ROOMS.ALL} variant={["ghost-blue", token ? "none" : "disabled"]} data-sentry-element="ButtonSwitch" data-sentry-source-file="index.tsx" />

      {/* bets table */}
      <div className={cn("flex flex-col gap-2", "mt-7")}>
        {/* headers row */}
        <Row className="pt-0 text-[#5B628C]" header data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Dict as="span" section="ResultFeed" name="gameName" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
          <Dict as="span" section="ResultFeed" name="user" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
          <Dict as="span" section="ResultFeed" name="betAmount" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
          <Dict as="span" section="ResultFeed" name="multiplier" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
          <Dict as="span" section="ResultFeed" name="payout" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
        </Row>

        {!bets?.length && <div className="flex justify-center items-center h-60 !bg-transparent">
            {bets?.length !== 0 ? <LoadingSpinner type="slots" /> : <Dict as="span" section="ResultFeed" name="no_bets" />}
          </div>}

        <RollingList orientation="vertical" className={cn("[&>*]:rounded-md")} skipAnimation={room !== prevRoomRef.current} ref={ref} data-sentry-element="RollingList" data-sentry-source-file="index.tsx">
          {/* body/bets rows */}
          {betHistory?.map?.((bet, i) => <Row key={`${bet.id}-${i}`} className={clsx("text-white", id[i] % 2 === 0 && "bg-[#191f3b]")}>
              <Link shallow href={{
            pathname: router.asPath,
            query: {
              modal: "bet",
              tab: "result",
              betId: bet.id
            }
          }} className="flex items-center gap-2">
                <span className="min-w-[15px] h-[20px] [&_*]:!w-[18px]">
                  {bet.game?.url && <GamesIcons code={bet.game.url} />}
                </span>

                {bet.game?.name && (bet.game.name.length > 15 ? bet.game.name.slice(0, 15) + "..." : bet.game.name)}
              </Link>

              <Link shallow href={{
            pathname: router.asPath,
            query: {
              modal: publicId === bet.user.publicId ? "profile" : "user",
              user: bet.user.publicId
            }
          }} className="flex items-center gap-2">
                {bet?.user?.rankLevel?.name && <RewardIcons code={bet?.user?.rankLevel?.name?.toUpperCase() as RewardCode} size={"large"} />}
                {bet?.user?.username || <Dict as="span" section="common" name="hidden" className="text-font-color-two" />}{" "}
                {!bet?.user?.username ? <Hidden /> : null}
              </Link>

              <ValueDisplay amount={bet.currencyAmount} size="small" textIcon currencyCode={bet.currency} />

              <span>{formatNumberLocale(bet.multiplier, locale)}x</span>

              <span className="flex items-center gap-2">
                <ValueDisplay amount={bet.currencyPayout} size="small" textIcon currencyCode={bet.currency} customClass={isWin(bet) ? "!text-[#FFB300]" : "!text-[#5B628C]"} style={{
              ...(isWin(bet) && {
                textShadow: "0px 0px 10px #FB8C00"
              })
            }} />

                <LocalArrow isWin={isWin(bet)} />
              </span>
            </Row>)}
        </RollingList>
      </div>
    </div>;
};
export default BetsFeed;