export default function HandshakeIcon({
  ...props
}) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props} data-sentry-element="svg" data-sentry-component="HandshakeIcon" data-sentry-source-file="handshake.tsx">
      <g clipPath="url(#clip0_20752_34959)" data-sentry-element="g" data-sentry-source-file="handshake.tsx">
        <path d="M2.81226 9.59896L1.69355 10.7176C1.3847 11.0265 1.3847 11.5273 1.69355 11.8363C2.00254 12.1452 2.50337 12.1452 2.81222 11.8363L3.93089 10.7176C4.23992 10.4086 4.23992 9.9078 3.93089 9.59896C3.62205 9.28993 3.12128 9.28993 2.81226 9.59896ZM6.5411 13.3279L5.42243 14.4465C5.11359 14.7554 5.11359 15.2562 5.42243 15.5651C5.73128 15.8741 6.23212 15.8741 6.5411 15.5651L7.65978 14.4465C7.96866 14.1375 7.96866 13.6367 7.65978 13.3279C7.35082 13.0188 6.85013 13.0188 6.5411 13.3279ZM9.15135 16.6837L9.52418 16.3109C9.83321 16.0019 9.83321 15.5011 9.52418 15.1922C9.21533 14.8834 8.7145 14.8834 8.40551 15.1922L8.03264 15.5651C7.7238 15.8741 7.7238 16.3749 8.03264 16.6837C8.34149 16.9928 8.8425 16.9928 9.15135 16.6837ZM5.42243 10.7176L3.55806 12.582C3.24904 12.891 3.24904 13.3919 3.55806 13.7007C3.86691 14.0096 4.36775 14.0096 4.67659 13.7007L6.5411 11.8363C6.84999 11.5273 6.84999 11.0265 6.5411 10.7177C6.23212 10.4086 5.73146 10.4086 5.42243 10.7177V10.7176Z" fill="url(#paint0_linear_20752_34959)" data-sentry-element="path" data-sentry-source-file="handshake.tsx" />
        <path d="M6.72814 2.70266L5.37392 1.3342C5.16794 1.12818 4.8341 1.12818 4.62812 1.3342L0.153535 5.80878C-0.0524805 6.0148 -0.0524805 6.34861 0.153535 6.55462L1.0677 7.48296C1.35709 7.77245 1.57516 8.12529 1.70466 8.5136L1.88069 9.04179L2.0672 8.85528C2.78681 8.13581 3.95779 8.13581 4.67741 8.85528C4.90539 9.08337 5.05288 9.35872 5.13637 9.64876C5.83127 9.28911 6.70564 9.39198 7.28762 9.97395C7.86963 10.556 7.97246 11.4303 7.61281 12.1254C7.90285 12.2089 8.17819 12.3561 8.40629 12.5842C8.7716 12.9495 8.94945 13.4312 8.94383 13.9111C9.42378 13.9055 9.90539 14.0834 10.2707 14.4487L11.0165 15.1944C11.3254 15.5034 11.8262 15.5034 12.1351 15.1944C12.4441 14.8855 12.4441 14.3847 12.1351 14.0757L10.83 12.7707C10.624 12.5647 10.624 12.2308 10.83 12.0248C11.036 11.819 11.3699 11.819 11.5757 12.0248L12.8809 13.33C13.1898 13.6389 13.6907 13.6389 13.9996 13.33C14.3084 13.0212 14.3084 12.5202 13.9996 12.2113L12.6944 10.9062C12.4886 10.7003 12.4886 10.3665 12.6944 10.1605C12.9004 9.95448 13.2344 9.95448 13.4402 10.1605L14.7454 11.4655C15.0543 11.7743 15.5551 11.7743 15.8641 11.4655C16.173 11.1566 16.173 10.6558 15.8641 10.347L12.6568 7.13966C11.6335 7.81624 10.2388 7.70476 9.33849 6.80441C9.13279 6.59871 8.79849 6.59871 8.59283 6.80441C7.56475 7.83245 5.89195 7.83245 4.86391 6.80441C4.24702 6.18766 4.24702 5.18391 4.86391 4.56717L6.72814 2.70266Z" fill="url(#paint1_linear_20752_34959)" data-sentry-element="path" data-sentry-source-file="handshake.tsx" />
        <path d="M13.3691 1.27951C13.1631 1.0735 12.8293 1.0735 12.6233 1.27951L11.5079 2.39484C11.3121 2.59066 11.0466 2.70068 10.7697 2.7007H8.87424C8.45469 2.70072 8.05234 2.86739 7.75567 3.16406L5.60882 5.31091C5.39992 5.51981 5.39992 5.84789 5.60882 6.05675C6.22775 6.67571 7.2271 6.67571 7.84606 6.05675C8.40389 5.49893 9.27797 5.44553 9.89637 5.89651C10.728 6.5032 11.8371 6.54029 12.5651 5.81231L12.6936 5.68378L16.3218 9.31201C16.3721 9.12621 16.3731 8.92955 16.3239 8.74705C16.2386 8.43078 16.3624 7.98128 16.5941 7.74963L17.8437 6.49993C18.0497 6.29392 18.0497 5.96007 17.8437 5.75423L13.3691 1.27951Z" fill="url(#paint2_linear_20752_34959)" data-sentry-element="path" data-sentry-source-file="handshake.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="handshake.tsx">
        <linearGradient id="paint0_linear_20752_34959" x1="5.60893" y1="9.36719" x2="5.60893" y2="16.9155" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="handshake.tsx">
          <stop stopColor="#87C1F2" data-sentry-element="stop" data-sentry-source-file="handshake.tsx" />
          <stop offset="1" stopColor="#3A8DDA" data-sentry-element="stop" data-sentry-source-file="handshake.tsx" />
        </linearGradient>
        <linearGradient id="paint1_linear_20752_34959" x1="8.04739" y1="1.17969" x2="8.04739" y2="15.4261" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="handshake.tsx">
          <stop stopColor="#87C1F2" data-sentry-element="stop" data-sentry-source-file="handshake.tsx" />
          <stop offset="1" stopColor="#3A8DDA" data-sentry-element="stop" data-sentry-source-file="handshake.tsx" />
        </linearGradient>
        <linearGradient id="paint2_linear_20752_34959" x1="11.7252" y1="1.125" x2="11.7252" y2="9.31201" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="handshake.tsx">
          <stop stopColor="#87C1F2" data-sentry-element="stop" data-sentry-source-file="handshake.tsx" />
          <stop offset="1" stopColor="#3A8DDA" data-sentry-element="stop" data-sentry-source-file="handshake.tsx" />
        </linearGradient>
        <clipPath id="clip0_20752_34959" data-sentry-element="clipPath" data-sentry-source-file="handshake.tsx">
          <rect width="18" height="18" fill="white" data-sentry-element="rect" data-sentry-source-file="handshake.tsx" />
        </clipPath>
      </defs>
    </svg>;
}